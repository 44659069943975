import image1 from '../assets/side/Image 2.png'
import image2 from '../assets/side/image 3.png'
import image3 from '../assets/side/image 4.png'
import image4 from '../assets/side/Image 5.png'

export const sideData = [
    {
       id:"1",
       img:image1,
       caption:"CHIEF GUESTS"
    },
   //  {
   //     id:"2",
   //     img:image2,
   //     caption:"Fee For KMA Members"
   //  },
   //  {
   //     id:"3",
   //     img:image3,
   //     caption:"Fee For Non-KMA Members"
   //  },
]