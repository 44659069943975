import React from "react";
import { Outlet } from "react-router-dom";

const Admin = () => {
  return (
    <div className="container mx-auto">
      <div>{<Outlet />}</div>
    </div>
  );
};

export default Admin;
