export const category = [
    
    {
        id:"KMA",
        categoryName:"KMA Member",
        amount:4000
    },
    {
        id:"Non-KMA",
        categoryName:"Non-KMA Member",
        amount:5000
    },
    // {
    //     id:"KMA Students",
    //     categoryName:"KMA Student Member",
    //     amount:2000
    // },
    // {
    //     id:"Non-Student Members",
    //     categoryName:"Non-KMA Student Member",
    //     amount:3000
    // },
    // {
    //     id:"International delegate",
    //     categoryName: "International delegate",
    //     amount:""
    // }
]