import React, { useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import emailjs from "@emailjs/browser";

const useEmailjsSender = () => {
    const [error, setError] = useState(null);
    const [response, setResponse] = useState(null);
    const [loading, setLoading] = useState(false);

    // service
    const ServiceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const PublicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    // Function to send the email
    const sendEmail = useCallback((data) => {
        console.log(data)
        setLoading(true);
        setError(null);

        // Initialize emailjs with the public key
        emailjs.init(PublicKey);

        emailjs
            .sendForm(ServiceId, TemplateId, data, PublicKey)
            .then((result) => {
                setResponse(result);
                console.log(result)
            })
            .catch((error) => {
                console.error("Error sending email:", error);
                setError("Something went wrong while sending the email");
            })
            .finally(() => {
                setLoading(false);
            });
    }, [ServiceId, TemplateId, PublicKey]);

    return { sendEmail, error, response, loading };
};

export default useEmailjsSender;
